export const ROUTES = {
  LOGIN: '/',
  HOME: '/banners',
  BANNERS: '/banners/banner-image-upload',
  EDIT_BANNERS: '/banners/edit-banner',
  CREATE_NEW_BANNER: '/banners/create-new-banner',
  HOME_SECTIONS: '/home-sections',
  CREATE_NEW_SECTION: '/home-sections/create-new-section',
  EDIT_SECTION: '/home-sections/edit-section',
  PRODUCT_PROMO: '/product-promo',
  CREATE_PRODUCT_PROMO: '/product-promo/create-product-promo',
  EDIT_PRODUCT_PROMO: '/product-promo/edit-product-promo',
  PRODUCT_IMAGE_UPLOAD: '/product-image-upload',
  UPLOAD_NEW_PRODUCT: '/product-image-upload/upload-new-product',
  EDIT_PRODUCT_IMAGE: '/product-image-upload/edit-product-image',
  PUSH_NOTIFICATION: '/push-notification',
  OFFERS: '/offers',
  CREATE_OFFER: '/offer/create-offer',
  LINK_OFFER: '/offer/link-offer',
  EDIT_LINK_OFFER: '/offer/edit-linked-offer',
  EDIT_OFFER: '/offer/edit-offer',
  PRODUCT_CATEGORY: '/product-category',
  EDIT_PRODUCT_CATEGORY: '/product-category/edit-product-category',
  EDIT_PRODUCT_CATEGORY_TIME: '/product-category/edit-product-category-time',
  COMBO_PRODUCT: '/combo-product',
  CREATE_COMBO_PRODUCT: '/combo-product/create-combo-product',
  EDIT_COMBO_PRODUCT: '/combo-product/edit-combo-product',
  CREATE_PRODUCT: '/product-image-upload/create-product',
  ORDER_DETAILS: '/order-details',
  PARTICULAR_ORDER_DETAILS: '/particular-order',
  OUTLET: '/outlet',
  CREATE_OUTLET: '/outlet/create',
  EDIT_OUTLET: '/outlet/edit',
  USERS: '/users',
  CREATE_USERS: '/users/create-users',
  REPORT: '/report',
  FEEDBACK: '/feedback',
  INVESTOR: '/investor/:id',
  INVESTORADDID: '/investor',
  INVESTOR_TREE: '/investor-trees-list',
  CREATE_INVESTOR: '/investor/create-investor',
  CREATE_INVESTORID: '/investor/create-investor/:id',
  CREATE_INVESTOR_TREE: '/investor-trees-list/create-investor-tree',
  EDIT_INVESTOR: '/investor/edit-investor',
  EDIT_INVESTORID: '/investor/edit-investor/:id',
  //  newly added
  EDIT_INVESTOR_TREE: '/investor-trees-list/edit-investor-tree',
  BLOCK_COD: '/block-cod-users',
  BLOCK_USER: '/block-cod-users/block-new-user',
  BK_VIDEO: '/bkVideos',
  DYNAMIC_ORDER: '/dymanic-order',
  CREATE_DEEP_LINK: '/create-deep-link',
  LIST_OF_DEEPLINKS: '/deeplinks',
  CHECKOUT_MODE: '/checkout-mode',
  CHARGES: '/charges-update',
  EMP_OFFERS: '/emp-offers',
  OPEN_SEARCH: '/open-search',
  NCR_MENU_FETCH: '/ncr-menu-fetch',
  CACHE_DURATION: '/cache-duration-update',
  CREATE_VIDEO: '/createBkVideo',
  CREATE_PRODUT_OFFER: '/create-product-offer',
  CREATE_DYNAMIC_ORDER: '/create-dynamic-order',
  EDIT_DYNAMIC_ORDER: '/edit-dynamic-order',
  EDIT_PRODUCT_OFFER: '/edit-product-offer',
  OFFER_ATTACHMENT: '/offers/doc-attachments',
  GAMIFICATION: '/gamification',
  ADDONS_SEPARATION: '/addons-separation',
  CREATE_CUSTOMIZATION: '/create-customization',
  CREATE_NCR_MENU_FETCH: '/create-ncr-menu',
  EDIT_CUSTOMIZATION: '/edit-customization',
  CARD_SEPARATION: '/card-separation',
  CREATE_CARD_CUSTOMIZATION: '/create-card-customization',
  EDIT_CARD_CUSTOMIZATION: '/edit-card-customization',
  CREATE_BANK_OFFERS: '/create-bank-offers',
  BANK_OFFERS: '/bank-offers',
  EDIT_BANK_OFFERS: '/edit-bank-offers',
  CATEGORY_TAGS: '/category-tags',
  CREATE_CATEGORY_TAGS: '/create-category-tags',
  EDIT_CATEGORY_TAGS: '/edit-category-tags',
  NUTRITION_INFO: '/nutrition',
  CREATE_NUTRITION: '/create-nutrition',
  EDIT_NUTRITION: '/edit-nutrition',
  KINGS_CLUB_OFFER: '/kings-club-offer',
  CREATE_KINGS_CLUB_OFFER: '/create-kings-club-offer',
  EDIT_KINGS_CLUB_OFFER: '/edit-kings-club-offer'
};

export const IMAGE_SIZE = {
  BANNERS: 1024 * 1024,
  PROMO: 1024 * 1024,
  PRODUCT_CATEGORY: 1024 * 1024,
  PRODUCT: 1024 * 2048,
  COMBO: 1024 * 1024
};

const value = {
  is_banners: true,
  is_category: true,
  is_home: true,
  is_offers: true,
  is_orders: true,
  is_outlets: true,
  is_product: true,
  is_reports: true,
  is_feedbacks: true,
  is_codBlock: true,
  is_bkvideos: true,
  is_investors: true,
  is_deeplinks: true,
  is_dynamicOrders: true,
  is_checkoutMode: true,
  is_charges: true,
  is_empOffers: true,
  is_openSearch: true,
  is_ncrMenuFetch: true,
  is_cacheDuration: true,
  is_gamification: true,
  is_cardSeparation: true,
  is_addonSeparation: true,
  is_bankOffers: true,
  is_categoryTags: true,
  is_nutritionInfo: true,
  is_kingClubOffer: true
};

const admin = true;

export const ACCESS = {
  MODULES: value,
  ADMIN: admin
};
